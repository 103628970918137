import React from 'react';
import PropTypes from 'prop-types';

const TwitterIcon = ({ className }) => (
  <svg
    width='32'
    height='26'
    viewBox='0 0 32 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className || ''}    
  >
    <path
      d='M32 3.10203C30.8571 3.59183 29.551 3.91837 28.2449 4.08163C29.551 3.26531 30.6939 1.95919 31.1837 0.489801C29.8776 1.30613 28.5714 1.79592 26.9388 2.12245C25.7959 0.816332 24 0 22.2041 0C18.6122 0 15.6735 2.93878 15.6735 6.53061C15.6735 7.02041 15.6735 7.5102 15.8367 7.99999C10.449 7.67346 5.55101 5.06122 2.28571 1.14285C1.79591 2.12244 1.46938 3.2653 1.46938 4.40816C1.46938 6.69388 2.61224 8.65306 4.40816 9.79592C3.2653 9.79592 2.28571 9.46939 1.46938 8.9796C1.46938 12.0816 3.75509 14.8571 6.69387 15.3469C6.20407 15.5102 5.55102 15.5102 4.89796 15.5102C4.40817 15.5102 4.08163 15.5102 3.59183 15.3469C4.40816 17.9592 6.85715 19.9184 9.79592 19.9184C7.51021 21.7143 4.7347 22.6939 1.63265 22.6939C1.14286 22.6939 0.653062 22.6939 0 22.5306C2.93878 24.3265 6.36734 25.4694 10.1224 25.4694C22.2041 25.4694 28.7347 15.5102 28.7347 6.85714V6.04081C30.0408 5.55102 31.1837 4.40815 32 3.10203Z'
    />
  </svg>
);

TwitterIcon.propTypes = {
  className: PropTypes.string,
};

export default TwitterIcon;