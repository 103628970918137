import React from 'react';
import PropTypes from 'prop-types';

const LogoStroke6 = ({ className }) => {
	const elementsFillColor = '#FBF8E9';
	const gradientStartColor = '#162D29';
	const gradientStopColor = '#59A0B7';

	return (
		<svg
			version='1.1'
			id='Layer_1'
			x='0px'
			y='0px'
			viewBox='-10 -10 520 520'
			className={className || ''}
		>
			<g>
				<linearGradient
					id='SVGID_1_'
					gradientUnits='userSpaceOnUse'
					x1='250'
					y1='2.1282'
					x2='250'
					y2='496.1281'
				>
					<stop offset='0' stopColor={gradientStartColor} />
					<stop offset='1' stopColor={gradientStopColor} />
				</linearGradient>
				<path
					fill='url(#SVGID_1_)'
					d='M250,497c-65.98,0-128-25.69-174.66-72.34C28.69,378,3,315.98,3,250c0-65.98,25.69-128,72.34-174.66
					C122,28.69,184.02,3,250,3c65.98,0,128,25.69,174.66,72.34C471.31,122,497,184.02,497,250c0,65.98-25.69,128-72.34,174.66
					S315.98,497,250,497z'
					stroke={elementsFillColor}
					strokeWidth='15'
				/>
			</g>
			<g>
				<path
					fill={elementsFillColor}
					d='M153.34,352.15c0,13.64,2.82,26.61,7.98,38.32c-8.92,3.15-18.58,4.91-28.66,4.91
					c-47.15,0-85.39-38.24-85.39-85.39c0-47.24,38.24-85.39,85.39-85.39c31.2,0,58.5,16.7,73.43,41.8
					C174.79,282.2,153.34,314.66,153.34,352.15z'
				/>
			</g>
			<g>
				<path
					fill={elementsFillColor}
					d='M335.57,352.15c0,11.51-2.29,22.55-6.43,32.55c-12.81,31.03-43.34,52.84-78.96,52.84
					c-35.2,0-65.37-21.28-78.43-51.65c-4.5-10.35-6.96-21.78-6.96-33.74c0-33.16,18.83-61.84,46.3-76c11.71-6.1,25.01-9.5,39.09-9.5
					c14.69,0,28.41,3.75,40.44,10.19C317.35,291.25,335.57,319.58,335.57,352.15z'
				/>
			</g>
			<g>
				<path
					fill={elementsFillColor}
					d='M455.06,309.5c0,47.12-38.24,85.39-85.39,85.39c-11.26,0-21.95-2.21-31.8-6.1
					c4.75-11.29,7.29-23.66,7.29-36.63c0-36.39-20.26-68.11-50.11-84.31c14.58-26.12,42.57-43.76,74.62-43.76
					C416.82,224.09,455.06,262.35,455.06,309.5z'
				/>
			</g>
			<path
				fill={elementsFillColor}
				d='M252.41,181.96c-0.73,1.51-2.72,1.53-3.48,0.04c-4.97-9.75-13.17-34.19-25.22-46.26
				c-11.92-11.93-34.47-21.43-43.6-26.61c-1.4-0.79-1.42-2.93-0.03-3.76c9.03-5.36,31.67-14.22,43.1-27.52
				c11.87-13.8,19.62-36.91,24.41-46.75c0.73-1.51,2.72-1.53,3.48-0.04c4.97,9.75,13.17,34.19,25.22,46.26
				c14.2,14.22,34.47,21.43,43.6,26.61c1.4,0.79,1.42,2.93,0.03,3.76c-9.03,5.36-32.73,15.45-43.1,27.52
				C266.01,147.78,257.2,172.11,252.41,181.96z'
			/>
		</svg>
	);
};

LogoStroke6.propTypes = {
  className: PropTypes.string,
};

export default LogoStroke6;
