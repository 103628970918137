import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import Header from './Header';

// const Footer = () => (
//   <footer className='bg-blue-700'>
//     <nav className='flex justify-between max-w-4xl p-4 mx-auto text-sm md:p-8'>
//       <p>GitHub</p>
//     </nav>
//   </footer>
// );

const Layout = ({ children }) => {
  return (
    <div className='flex flex-col min-h-screen items-center font-sans text-gray-900'>
      <Helmet
        bodyAttributes={{ class: 'antialiased bg-brand-pearl' }}
      />
      <Header />
      <main className='w-full'>{children}</main>
      {/* <Footer /> */}
    </div>
  );
};
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object,
};

export default Layout;
