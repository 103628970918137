import React from 'react';
import { Link } from 'gatsby';

import TwitterIcon from './icons/TwitterIcon';
import Logo from './icons/Logo';

const Header = () => {
  return (
    <header className='flex justify-between px-4 py-2 items-center w-full xl:max-w-screen-xl fixed z-50'>
      <div className='flex items-center'>
        <Logo className='w-12 h-12' />
        <span className='text-brand-alabaster lowercase ml-4 font-montserrat font-bold'>
          Lareclub
        </span>
      </div>
      <nav className='flex items-center'>
        <a
          href='https://twitter.com/lareclub'
          target='_blank'
          rel='noopener noreferrer'
        >
          <TwitterIcon className='fill-current text-brand-alabaster' />
        </a>
        <Link
          to='/blog'
          className='bg-brand-gamboge hover:bg-brand-hovered-link active:bg-brand-dark-grey text-brand-white-smoke inline-block px-5 py-2 rounded-lg ml-3 transition-colors duration-150 font-montserrat font-semibold'
        >
          Blog
        </Link>
      </nav>
    </header>
  );
};

export default Header;
